/* You can add global styles to this file, and also import other style files */

.loginlayer
{
background-color: grey;
opacity: 0.3;
height: 120%;
position:absolute;
top:90px;
width: 100%;
z-index: 1;
}

@media (max-device-width: 770px) {
    .loginlayer
    {
    background-color: grey;
    opacity: 0.3;
    height: 140%;
    position:absolute;
    top: 180px;
    width: 100%;
    z-index: 1;
      }
    }

.homelayer
{
background-color: grey;
opacity: 0.3;
height: 123%;
position:absolute;
top:90px;
width: 100%;
z-index: 1;
}


.setpasswordlayer
{
background-color: grey;
opacity: 0.3;
height: 110%;
position:absolute;
top:90px;
width: 100%;
z-index: 1;
}

.recoverylayer
{
background-color: grey;
opacity: 0.3;
height: 119%;
position:absolute;
top:90px;
width: 100%;
z-index: 1;
}
.anchorTagCustom{
  color: #0093d0 !important;
}
